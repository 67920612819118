import Container from "../components/Container/Container";
import {Breadcrumb, Button, Card, Flex, Form, message, Space, Table, Tag, Typography} from "antd";
import {formatDateTime, numberFormat} from "../helpers/helpers";
import Search from "../components/Search/Search";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAdd} from "@fortawesome/free-solid-svg-icons";
import FormDrawer from "../components/FormDrawer/FormDrawer";
import {useContext, useEffect, useState} from "react";
import useAuthHeader from "react-auth-kit/hooks/useAuthHeader";
import LoadingContext from "../contexts/LoadingContext";
import TextCopy from "../components/TextCopy";
import getWithdrawTransactions from "../api/withdraw-transaction/getWithdrawTransactions";
import dayjs from "dayjs";
import ReadMoreText from "../components/ReadMoreText";
import getBankTransfers from "../api/withdraw-transaction/getBankTransfers";
import getBankReceives from "../api/withdraw-transaction/getBankReceives";
import createWithdrawTransfer from "../api/withdraw-transaction/createWithdrawTransfer";
import getBankWithdraw from "../api/withdraw-account/getBankWithdraw";
import createTransferOutSide from "../api/withdraw-transaction/createTransferOutSide";

function WithdrawTransaction() {
    const [form] = Form.useForm();
    const [formTransfer] = Form.useForm();
    const [formOutSide] = Form.useForm();
    const accessToken = useAuthHeader();
    const {setLoading} = useContext(LoadingContext);
    const [messageApi, contextHolder] = message.useMessage();
    const [dataSource,setDataSource] = useState([]);
    const [tableLoading,setTableLoading] = useState(false);
    const [open,setOpen] = useState(false);
    const [openOutSide,setOpenOutSide] = useState(false);
    const [listBankTransfer, setListBankTransfer] = useState([]);
    const [listBankReceives, setListBankReceives] = useState([]);
    const [banks,setBanks] = useState([]);
    const [tableParams, setTableParams] = useState({
        pagination: {
            current: 1,
            pageSize: 10,
        },
        search: {
            createdAt: [dayjs().startOf('day'), dayjs().endOf('day')]
        }
    });

    const items = [
        {
            name: 'accountName',
            type: 'text',
            placeholder: 'Tên tài khoản'
        },
        {
            name: 'accountNumber',
            type: 'text',
            placeholder: 'Số tài khoản'
        },
        {
            name: 'createdAt',
            type: 'date'
        }
    ]

    const itemTransfers = [
        {
            name: 'bankAccountTransferId',
            placeholder: 'Chọn tài khoản chuyển',
            type: 'select',
            label: 'Tài khoản chuyển',
            required: true,
            options: listBankTransfer
        },
        {
            name: 'bankAccountReceiveId',
            placeholder: 'Chọn tài khoản nhận',
            type: 'select',
            label: 'Tài khoản nhận',
            required: true,
            options: listBankReceives
        },
        {
            name: 'amount',
            placeholder: 'Nhập số tiền',
            type: 'number',
            label: 'Số tiền',
            required: true,
        },
        {
            name: 'comment',
            placeholder: 'Nhập nội dung',
            type: 'text',
            label: 'Nội dung',
        }
    ];

    const itemOutSide = [
        {
            name: 'bankAccountTransferId',
            placeholder: 'Chọn tài khoản chuyển',
            type: 'select',
            label: 'Tài khoản chuyển',
            required: true,
            options: listBankTransfer
        },
        {
            name: 'bankCode',
            placeholder: 'Chọn ngân hàng',
            type: 'select',
            label: 'Ngân hàng nhận',
            required: true,
            options: (banks || []).map((item) => {
                return {
                    value: item.bankCode,
                    label: `${item.bankName} - ${item.displayName} (${item.shortName})`
                }
            })
        },
        {
            name: 'accountNumber',
            placeholder: 'Nhập số tài khoản',
            type: 'text',
            label: 'Số tài khoản',
            required: true,
        },
        {
            name: 'accountName',
            placeholder: 'Nhập tên tài khoản',
            type: 'text',
            label: 'Tên tài khoản',
            required: true,
        },
        {
            name: 'amount',
            placeholder: 'Nhập số tiền',
            type: 'number',
            label: 'Số tiền',
            required: true,
        },
        {
            name: 'comment',
            placeholder: 'Nhập nội dung',
            type: 'text',
            label: 'Nội dung',
        }
    ];


    const columns = [
        {
            title: 'ID',
            dataIndex: 'uuid',
            align: 'center'
        },
        {
            title: 'Tài khoản gửi',
            dataIndex: 'bankSender',
            align: 'center',
            render: (_, record) => {
                return (
                    <Flex vertical={true} justify={'center'} align={'center'} gap={'small'}>
                        <Tag color={'#53c8f3'}>{record.bankNameSender}</Tag>
                        <TextCopy value={record.username}/>
                    </Flex>
                )
            }
        },
        {
            title: 'Ngân hàng',
            dataIndex: 'bankName',
            align: 'center'
        },
        {
            title: 'Số tài khoản',
            dataIndex: 'accountNumber',
            align: 'center',
            render: (value) => {
                return (
                    <TextCopy value={value}/>
                )
            }
        },
        {
            title: 'Tên tài khoản',
            dataIndex: 'accountName',
            align: 'center',
            render: (value) => {
                return <Tag>{value}</Tag>
            }
        },
        {
            title: 'Số tiền',
            dataIndex: 'amount',
            sorter: true,
            align: 'center',
            render: (value) => {
                return numberFormat(value);
            }
        },
        {
            title: 'Phí',
            dataIndex: 'fee',
            sorter: true,
            align: 'center',
            render: (value) => {
                return numberFormat(value);
            }
        },
        {
            title: 'Nội dung',
            dataIndex: 'comment',
            width: '200px',
            align: 'center'
        },
        {
            title: 'Kiểu',
            dataIndex: 'isAuto',
            align: 'center',
            editable: true,
            inputType: 'select',
            filters: [
                {
                    text: 'Tự động',
                    value: true
                },
                {
                    text: 'Thủ công',
                    value: false
                }
            ],
            render: (value) => {
                if (value) {
                    return <Tag color={'green'}>Tự động</Tag>
                }
                return <Tag color={'blue'}>Thủ công</Tag>
            }
        },
        {
            title: 'Trạng thái',
            dataIndex: 'status',
            align: 'center',
            filters: [
                {
                    text: 'Hoàn thành',
                    value: true
                },
                {
                    text: 'Chưa hoàn thành',
                    value: false
                },
            ],
            render: (value) => {
                if (value) {
                    return <Tag color={'green'}>Hoàn thành</Tag>
                }
                return <Tag color={'blue'}>Chưa hoàn thành</Tag>
            }
        },
        {
            title: 'Lỗi',
            dataIndex: 'isError',
            align: 'center',
            width: '200px',
            render: (value) => {
                if (value) {
                    return <Tag color={'red'}>Lỗi</Tag>
                }
            }
        },
        {
            title: 'Thông tin',
            dataIndex: 'message',
            align: 'center',
            render: (value) => {
                return <ReadMoreText text={value ?? ''} maxLength={50}/>
            }
        },
        {
            title: 'Xử lý bởi',
            dataIndex: 'handler',
            align: 'center'
        },
        {
            title: 'Thời gian tạo',
            dataIndex: 'createdAt',
            sorter: true,
            render: (value) => {
                return formatDateTime(value)
            }
        }
    ]

    const fetchDataTable = async () => {
        setTableLoading(true);
        const response = await getWithdrawTransactions(accessToken,tableParams);
        if (response.success) {
            setDataSource(response.data.data);
            setTableParams({
                ...tableParams,
                pagination: {
                    ...tableParams.pagination,
                    total: response.data.total
                },
            });
        }
        setTableLoading(false);
    }

    const handleSearch =  () => {
        const params = form.getFieldsValue();
        setTableParams({
            ...tableParams,
            search: {
                ...params
            }
        })
    }

    const handleTableChange = (pagination, filters, sorter) => {
        setTableParams({
            ...tableParams,
            pagination,
            filters,
            sorter,
        });
    };

    const handleSubmitTransfer = async (values) => {
        setLoading(true);
        const response = await createWithdrawTransfer(accessToken, values);
        if (response.success) {
            message.success('Tạo đơn chuyển tiền thành công.');
            await fetchDataTable();
        }
        else {
            message.error(response.message);
        }
        setLoading(false);
    }

    const handleSubmitTransferOutSide = async (values) => {
        setLoading(true);
        const response = await createTransferOutSide(accessToken, values);
        if (response.success) {
            message.success('Tạo đơn chuyển tiền thành công.');
            await fetchDataTable();
        }
        else {
            message.error(response.message);
        }
        setLoading(false);
    }

    useEffect(() => {
        const fetchData = async () => {
            const response = await getBankWithdraw(accessToken);
            if (response.success) {
                setBanks(response.data);
            }
        }
        fetchData();
    }, []);

    useEffect(() => {
        fetchDataTable();
    }, [
        tableParams.pagination?.current,
        tableParams.pagination?.pageSize,
        tableParams?.sorter,
        tableParams?.search,
        JSON.stringify(tableParams.filters),
    ]);

    useEffect(() => {
        const fetchBankTransfer = async () => {
            const response = await getBankTransfers(accessToken);
            if (response.success) {
                setListBankTransfer(response.data.map(item => ({
                    label:  `(${item.bankName}) ${item.accountNumber} - ${item.accountName}`,
                    value: item.bankAccountId
                })))
            }
        }
        fetchBankTransfer().finally(() => {});
    }, []);

    useEffect(() => {
        const fetchBankReceives = async () => {
            const response = await getBankReceives(accessToken);
            if (response.success) {
                setListBankReceives(response.data.map(item => ({
                    label:  `(${item.bankName}) ${item.accountNumber} - ${item.accountName}`,
                    value: item.id
                })))
            }
        }
        fetchBankReceives().finally(() => {})
    }, [])

    return (
        <Container>
            {contextHolder}
            <Space
                style={{
                    marginBottom: 16,
                }}
            >
                <Button type={'primary'} ghost={true} onClick={() => setOpen(true)} icon={<FontAwesomeIcon icon={faAdd}/>}>Tạo đơn chuyển</Button>
                <Button type={'primary'} ghost={true} onClick={() => setOpenOutSide(true)} icon={<FontAwesomeIcon icon={faAdd}/>}>Tạo đơn chuyển ngoài</Button>
            </Space>
            <Search
                items={items}
                form={form}
                onSearch={handleSearch}
            />
            <Table
                rowKey={'uuid'}
                columns={columns}
                dataSource={dataSource}
                bordered={true}
                scroll={{
                    x: 2000,
                }}
                loading={tableLoading}
                pagination={tableParams.pagination}
                onChange={handleTableChange}
            />
            <Form
                form={formTransfer}
                onFinish={handleSubmitTransfer}
            >
                <FormDrawer
                    open={open}
                    onClose={() => setOpen(false)}
                    items={itemTransfers}
                    onSubmit={formTransfer.submit}
                    title={'Tạo đơn chuyển tiền'}
                    width={800}
                />
            </Form>
            <Form
                form={formOutSide}
                onFinish={handleSubmitTransferOutSide}
            >
                <FormDrawer
                    open={openOutSide}
                    onClose={() => setOpenOutSide(false)}
                    items={itemOutSide}
                    onSubmit={formOutSide.submit}
                    title={'Tạo đơn chuyển tiền'}
                    width={800}
                />
            </Form>
        </Container>
    )
}

export default WithdrawTransaction;
import Container from "../components/Container/Container";
import {Button, Form, message, Modal, Space, Table, Tag} from "antd";
import {useContext, useEffect, useState} from "react";
import useAuthHeader from "react-auth-kit/hooks/useAuthHeader";
import {convertUnixTimestampToDateTime, createFileName, numberFormat} from "../helpers/helpers";
import getTransactions from "../api/bank-transaction/getTransactions";
import TextCopy from "../components/TextCopy";
import Search from "../components/Search/Search";
import getBanks from "../api/bank/getBanks";
import LoadingContext from "../contexts/LoadingContext";
import {EditOutlined, ExportOutlined} from "@ant-design/icons";
import FormDrawer from "../components/FormDrawer/FormDrawer";
import getProcessingPayIns from "../api/bank-transaction/getProcessingPayIns";
import updatePayIn from "../api/bank-transaction/updatePayIn";
import dayjs from "dayjs";
import exportFile from "../api/bank-transaction/exportFile";

function BankTransaction() {
    const [dataSource,setDataSource] = useState([]);
    const [tableLoading,setTableLoading] = useState(false);
    const accessToken = useAuthHeader();
    const [form] = Form.useForm();
    const [options, setOptions] = useState([]);
    const { setLoading } = useContext(LoadingContext);
    const [openDrawer, setOpenDrawer] = useState(false);
    const [optionPayIns, setOptionPayIns] = useState([]);
    const [dataOptionsPayIn, setDataOptionsPayIn] = useState([]);
    const [formEdit] = Form.useForm();
    const [bankTransactionId, setBankTransactionId] = useState(null);
    const [messageApi, contextHolder] = message.useMessage();
    const [summaryData, setSummaryData] = useState({
        totalAmount: 0,
        totalFee: 0
    });
    const [tableParams, setTableParams] = useState({
        pagination: {
            current: 1,
            pageSize: 10,
        },
        search: {
            time: [dayjs().startOf('day'), dayjs().endOf('day')]
        }
    });

    const items = [
        {
            name: 'transactionId',
            type: 'text',
            placeholder: 'Mã giao dịch'
        },
        {
            name: 'accNo',
            type: 'text',
            placeholder: 'Tài khoản'
        },
        {
            name: 'content',
            type: 'text',
            placeholder: 'Nội dung'
        },
        {
            name: 'target',
            type: 'text',
            placeholder: 'Đối tượng'
        },
        {
            name: 'time',
            type: 'date',
        }
    ]

    const columns = [
        {
            title: 'ID',
            dataIndex: 'uuid',
            align: 'center'
        },
        {
            title: 'Ngân hàng',
            dataIndex: 'bankName',
            align: 'center',
            filterMode: 'tree',
            filters: options,
            render: (value) => {
                return <Tag>{value}</Tag>
            }
        },
        {
            title: 'Số tài khoản',
            dataIndex: 'accountNumber',
            align: 'center',
            render: (value) => {
                return <TextCopy value={value}/>
            }
        },
        {
            title: 'Mã giao dịch',
            dataIndex: 'transactionId',
            align: 'center',
            render: (value) => {
                return <TextCopy value={value}/>
            }
        },
        {
            title: 'Số tiền',
            dataIndex: 'amount',
            align: 'center',
            sorter: true,
            render: (value) => {
                return numberFormat(value);
            }
        },
        {
            title: 'Phí',
            dataIndex: 'fee',
            align: 'center'
        },
        {
            title: 'Chuyển/Nhận',
            dataIndex: 'io',
            align: 'center',
            filters: [
                {
                    text: 'Chuyển tiền',
                    value: -1
                },
                {
                    text: 'Nhận tiền',
                    value: 1
                }
            ],
            render: (value) => {
                if (value === 1) {
                    return <Tag color={'green'}>Nhận</Tag>
                }
                return <Tag color={'red'}>Chuyển</Tag>
            }
        },
        {
            title: 'Loại giao dịch',
            dataIndex: 'transactionType',
            align: 'center',
            filters: [
                {
                    text: 'IN',
                    value: 'in'
                },
                {
                    text: 'OUT',
                    value: 'out'
                },
                {
                    text: 'Không xác định',
                    value: 'unknown'
                }
            ],
            render: (_, record) => {
                if (record.payInId) {
                    return <Tag color={'blue'}>IN</Tag>
                }
                else if (record.payOutId) {
                    return <Tag color={'blue'}>OUT</Tag>
                }
                else {
                    return <Tag color={'red'}>Unknown</Tag>
                }
            }
        },
        {
            title: 'Trạng thái',
            dataIndex: 'status',
            align: 'center',
            render: (value) => {
                if (value) {
                    return <Tag color={'green'}>Thành công</Tag>
                }
                return <Tag color={'red'}>Lỗi</Tag>
            }
        },
        {
            title: 'Nội dung',
            dataIndex: 'content',
            align: 'center',
            width: '240px',
            render: (value) => {
                return <TextCopy value={value}/>
            }
        },
        {
            title: 'Đối tượng',
            dataIndex: 'target',
            align: 'center',
            render: (value) => {
                return <Tag>{value}</Tag>
            }
        },
        {
            title: 'Thời gian',
            dataIndex: 'time',
            align: 'center',
            sorter: true,
            render: (value) => {
                return convertUnixTimestampToDateTime(value);
            }
        },
        {
            title: 'Hành động',
            dataIndex: 'operation',
            align: 'center',
            render: (_,record) => {
                return (
                    <Space>
                        {(record.io === 1 && !record.payInId) && (
                            <Button onClick={() => handleOpenDrawer(record.bankTransactionId)} icon={<EditOutlined/>} title={'Xác nhận giao dịch'}/>
                        )}
                    </Space>
                )
            }
        }
    ]

    const formItems = [
        {
            name: 'payInId',
            placeholder: 'Chọn giao dịch',
            type: 'select',
            label: 'Giao dịch',
            required: true,
            options: optionPayIns
        },
        {
            name: 'code',
            label: 'Mã khớp',
            type: 'text',
            disabled: true,
        },
        {
            name: 'amount',
            label: 'Số tiền tạo',
            type: 'number',
            disabled: true,
        },
        {
            name: 'username',
            label: 'Đối tác',
            type: 'text',
            disabled: true,
        }
    ];


    const handleOpenDrawer = async (id) => {
        const response = await getProcessingPayIns(accessToken);
        if (response.success) {
            const optionData = response.data.map((item) => {
                return {
                    value: item.payInId,
                    label: `${item.code} - ${numberFormat(item.amount)}`
                }
            });
            setBankTransactionId(id);
            setDataOptionsPayIn(response.data);
            setOptionPayIns(optionData);
            setOpenDrawer(true);
        }
    }

    const handleTableChange = (pagination, filters, sorter) => {
        setTableParams({
            ...tableParams,
            pagination,
            filters,
            sorter,
        });
    };

    const handleChangeFormDrawer = (e) => {
        const data = dataOptionsPayIn.find(value => e === value.payInId);
        formEdit.setFieldsValue({
            code: data.code,
            amount: data.amount,
            username: data.username
        });
    }

    const handleSubmit = async (values) => {
        const response = await updatePayIn(accessToken, bankTransactionId, values);
        if (response.success) {
            messageApi.success('Cập nhật đơn thành công');
        } else {
            messageApi.error(response.message);
        }
    }

    const handleSearch =  () => {
        const params = form.getFieldsValue();
        setTableParams({
            ...tableParams,
            search: {
                ...params
            }
        })
    }

    const fetchDataTable = async () => {
        setTableLoading(true);
        const response = await getTransactions(accessToken,tableParams);
        if (response.success) {
            setDataSource(response.data.data);
            setTableParams({
                ...tableParams,
                pagination: {
                    ...tableParams.pagination,
                    total: response.data.total
                },
            });
            setSummaryData({
                totalAmount: response.data.totalAmount,
                totalFee: response.data.totalFee
            })
        }
        setTableLoading(false);
    }

    const handleExportFile = async () => {
        setLoading(true);
        const response = await exportFile(accessToken, tableParams.search, createFileName('bank_transaction', 'xlsx'));
        if (!response.success) {
            message.error(response.message);
        }
        setLoading(false);
    }

    useEffect(() => {
        setLoading(true);
        const fetchBanks = async () => {
            const response = await getBanks(accessToken);
            if (response.success) {
                setOptions(response.data.map(item => ({
                    text:  item.displayName,
                    value: item.bankName
                })))
            }
        }
        fetchBanks().finally(() => setLoading(false));
    }, []);

    useEffect(() => {
        fetchDataTable();
    }, [
        tableParams.pagination?.current,
        tableParams.pagination?.pageSize,
        JSON.stringify(tableParams.sorter),
        JSON.stringify(tableParams.search),
        JSON.stringify(tableParams.filters),
    ]);

    return (
        <Container>
            {contextHolder}
            <Space
                style={{
                    marginBottom: '20px'
                }}
            >
                <Button onClick={handleExportFile} type={'primary'} icon={<ExportOutlined/>} title={'Xuất file'}>Xuất file</Button>
            </Space>
            <Search
                form={form}
                items={items}
                onSearch={handleSearch}
            />
            <Table
                rowKey={'uuid'}
                columns={columns}
                dataSource={dataSource}
                bordered={true}
                scroll={{
                    x: 1800
                }}
                style={{
                    fontWeight: 500,
                }}
                loading={tableLoading}
                pagination={tableParams.pagination}
                onChange={handleTableChange}
                summary={(data) => {

                    return (
                        <Table.Summary.Row>
                            <Table.Summary.Cell index={0} colSpan={4}>
                                Tổng tiền
                            </Table.Summary.Cell>
                            <Table.Summary.Cell index={1} align={'center'}>
                                <Tag color={'blue'}>{numberFormat(summaryData.totalAmount)}</Tag>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell index={1} align={'center'}>
                                <Tag color={'blue'}>{numberFormat(summaryData.totalFee)}</Tag>
                            </Table.Summary.Cell>
                        </Table.Summary.Row>
                    );
                }}
            />
            <Form
                form={formEdit}
                layout={'vertical'}
                onFinish={handleSubmit}
            >
                <FormDrawer
                    title={'Xác nhận giao dịch'}
                    items={formItems}
                    open={openDrawer}
                    onClose={() => setOpenDrawer(false)}
                    onChange={handleChangeFormDrawer}
                    onSubmit={formEdit.submit}
                />
            </Form>
        </Container>
    )
}
export default BankTransaction;